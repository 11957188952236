/*
*#root {
  display: flex;
}

button {
  min-width: 130px;
  border-radius: 8px;
  border: 1px solid #1a1a1a;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: border-color 0.25s;
}

input {
  border-radius: 8px;
  border: 1px solid #1a1a1a;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  transition: border-color 0.25s;
}

button:hover {
  background-color: #dddddd;
}
button:active {
  background-color: #cccccc;
}

#root .left-side {
  margin: 10px;
  padding: 10px;
}

#root .right-side .waiting {
  margin: 10px;
  padding: 10px;
  display: flex;
}
#root .right-side {
  display: flex;
  margin: 10px;
  padding: 10px;
  flex-wrap: wrap;
}

#root button,
#root input {
  margin: 10px;
  padding: 10px;
}

#root h3 {
  padding: 0 10px;
}

#root .button-on {
  background-color: rgb(7, 158, 255);
  color: white;
}

#root .right-side video {
  height: 300px !important;
  max-width: 400px;
  margin: 10px;
  border-radius: 4px;
  aspect-ratio: 16/9;
}*

#camera-video {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

#remote-video {
  width: 10%;
  height: 10%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
*/

.body {
    width: 100%;
    height: 100%;
}
.bigVideo {
    height: 100%;
    background-color: #272727;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.bigNoVideoImg {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    overflow: hidden;
}

.remoteNameContainer {
    position: absolute;
    bottom: 117px;
    left: 30px;
    display: flex;
    align-items: center;
    gap: 14px;
}

.bigName {
    background-color: #00000080;
    padding: 9px 12px;
    border-radius: 5px;
    color: #F7F7FC;
    font-size: 18px;
    display: flex;
    align-items: center;

   /* text-align: center;
    margin-top: 14px;
    font-size: 22px;
    font-weight: bold;
    line-height: 33px;*/
}

.bigNameLabel {
    position: absolute;
    left: 30px;
    bottom: 120px;
    padding: 11px 9px;
    color: #F7F7FC;
    border-radius: 5px;
    font-size: 18px;
    background-color: #00000080;
}

.bigPosition {
    font-size: 17px;
    color: #A7A7A7;
}

.controls {
    position: absolute;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 28px;
}

.btn {
    width: 64px;
    height: 64px;
    background-color: #000000BF;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none !important;
    border: none;
}
.smallVideo {
    border-radius: 5px;
    width: 25%;
    height: 23%;
    position: absolute;
    left: 15px;
    top: 15px;
    background-color: #151515;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.smallNoVideo {
    display: flex;
    justify-content: center;
    align-items: center;
}
.smallNoVideoImg {
    width: 58px;
    height: 58px;
    border-radius: 100px;
}

.smallFooter {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #00000080;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 6px 8px 24px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.smallIcons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.mutedIcon {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F8000F;
    border-radius: 100%;
}

.hidden {
    visibility: hidden;
}

@media (hover: hover) {
    .btn:hover {
        background-color: #00000059;
    }
}

.disabled {
    background-color: #F8000F;
}
@media (hover: hover) {
    .disabled:hover {
        background-color: #F8000F59;
    }
}
.modal {
    margin-top: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
}

.modalTitle {
    color: #303437;
    font-size: 18px;
    margin-top: 16px;
}
.btnContainer {
    margin-top: 27px;
    width: 68%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.modalText {

}
.modalBtn {
    background-color: #fff;
    width: 100%;
    border: none;
    border-top-width: 1px;
    border-top-color: #DADADA;
    border-top-style: solid;
    font-size: 16px;
    font-weight: bolder;
    color: #4D6D9C;
    padding: 18px;
    text-align: center;
    cursor: pointer;
}
.closeBtnDiv {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
}

.isVisible {
    height: calc(100% -  160px);
}
