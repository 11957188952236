.modalOverlay {
  position: fixed;
  top:  0;
  right:  0;
  bottom:  0;
  left:  0;
  background-color: rgba(0,  0,  0,  0.78); /* Black with  78% opacity */
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: white;
  border-radius:  9px;
  overflow: hidden;
  width: 85%;
  max-width: 435px;
  padding-bottom: 40px;
}
