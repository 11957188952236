.body {
  background-color: #F9F5E6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left:  44px;
  padding-right:  44px;
  height:  100%;
}
.container {
  max-width: 345px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon {
  font-size: 57px;
}

.loader {
  position: absolute;
  top:  50%;
  left:  50%;
  transform: translate(-50%, -50%);
  margin-top:  12px;
}

.input {
  width:  100%;
  background-color: #fff;
  border-radius:  24px;
  color: #000000;
  font-size:  20px;
  border: 2px solid #F1EDDD;
  text-align: center;
  margin-bottom:  20px;
  padding: 12px 24px;
  outline: none !important;

  &:focus{
    border-color: #BEB9AA;
  }
}
.error {
  border-color: #F39487;
}
.numberInputContainer {
  width:  100%;
  height:  50px;
  margin-top:  10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.codeDigit {
  font-size:  35px;
  color: #303437;
}

.numberInputBackground {
  width:  100%;
  height:  100%;
  border-radius:  8px;
}

.numberInput {
  width:  0;
  height:  0;
}

.emoji {
  font-size:  46px;
}

.title {
  font-size:  24px;
  font-weight:  bold;
  margin-top:  24px;
  text-align: center;
}

.subTitle {
  font-size:  20px;
  font-weight:  400;
  text-align: center;
}

.textColor {
  color: rgba(48,  52,  55,  1);
}

.hintBlock {
  margin-top:  11px;
  align-self: flex-end;
}

.hintText {
  margin-top:  11px;
  color: rgba(48,  52,  55,  1);
}
