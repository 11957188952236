.blueButton {
    box-sizing: border-box;
    width:  100%;
    padding:  13px  24px;
    text-align: center;
    background-color: #4D6D9C;
    border-radius:  36px;
    border-width: 0;
    line-height:  35px;
    height:  60px;
    cursor: pointer;
    color: #F7F7FC;
    font-size:  20px;
    font-weight:  600;
    &:disabled {
     opacity:  0.5;
    }
&:hover {
     background-color: darken(#4D6D9C,  10%);
 }
}
.cancel {
    background-color: transparent;
    border: 1px solid #4D6D9C;
    color: #4D6D9C;
    &:hover {
        color: #F7F7FC;
    }
}
.loader {
    display: inline-block;
    width:  32px;
    height:  32px;
    border:  4px solid #f3f3f3;
    border-top:  4px solid #3498db;
    border-radius:  50%;
    animation: spin  2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
