.body {
  height: 100%;
  background-color: #F9F5E6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 44px;
}
.container {
  height: 100%;
  max-width: 590px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon {
  font-size: 57px;
}

.iconNotActive path  {
  fill: red
}

.title {
  font-size:  22px;
  font-weight:  bold;
  margin-top:  24px;
}

.subTitle {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.onThisCall {
  font-size: 20px;
  font-weight: bold;
}

.camera {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 16px;
  object-fit: contain;
}

.btn {
  width: 58px;
  height: 58px;
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

@media (hover: hover) {
  .btn:hover {
    color: rgb(255, 0, 140);
  }
}

.buttonsContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 38px;
  width: 100%;
  margin-top: 16px;
}

.submitBtnContainer {
  width: 100%;
  max-width: 345px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.cameraContainer {
  margin-top: 34px;
  width: 100%;
  height:  0;
  position: relative;
  padding-top: 62%;
  border-radius: 16px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cameraOffText {
  position: absolute;
  align-self: center;
  top: 44%;
  color: #fff;
}

.permissionsError {
  color: #e74c3c;
  text-align: center;
  line-height: 20px;
  margin-top: 10px;
}

.cameraMenu {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.cameraOption {
  cursor: pointer;
}

.cameraOption:hover {
  background-color: #F1F1F1;
}
