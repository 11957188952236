.body {
  background-color: #F9F5E6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left:  44px;
  padding-right:  44px;
  height:  100%;
}

.container {
  max-width: 345px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon {
  font-size: 57px;
}

.title {
  font-size:  24px;
  font-weight:  bold;
  margin-top:  24px;
  text-align: center;
}

.subTitle {
  font-size:  20px;
  font-weight:  400;
  text-align: center;
}

.table {
  margin-top: 29px;
  margin-bottom: 27px;
  background-color: #fff;
  border-radius: 11px;
  padding: 12px 25px;
  border: 2px solid #EFEBDB;
  color: #303437;
}

.tableRow {
  margin-top: 12px;
  padding-bottom: 11px;
  border-bottom: 2px solid #F1F1F1
}

.lastRow {
  border-bottom: none;
}

.tableName {
  font-weight: 600;
}

.tableValue {

}
.emojiBlock {
  margin-top: 29px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
}



.emojiContainer {
  position: relative;
  box-sizing: border-box;
  border: 2px solid #F1EBD2;
  width: 94px;
  height: 94px;
  border-radius: 100px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}


.emoji {
  font-size: 64px;
  margin-top: -8%;
  margin-left: 2%;
}

@media (max-width: 540px) {
  .emojiBlock {
    gap: 6px;
    max-width: 200px;
  }

  .emojiContainer {
    width: 72px;
    height: 72px;
  }

  .emoji {
    font-size: 48px;
  }
}

.markItem {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emojiSelected {
  background-color: transparent;
  border: 4px solid #2AAC7E;
}

.emojiName {
  font-size: 14px;
}
.selectedIcon {
  position: absolute;
  top: -13%;
  right: 0;
  background-color: #2AAC7E;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 4px solid #F9F5E6;
}
