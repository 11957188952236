.animated-error-msg {
  margin-bottom:  0;
  opacity:  0;
  font-size:  18px;
  color: #F39487;
  transition: margin-bottom  0.3s ease, opacity  0.3s ease;
}

.animated-error-msg.visible {
  margin-bottom:  20px;
  opacity:  1;
}
